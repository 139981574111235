import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Banner from '../components/CryptoGuysDetail/Banner'
import Footer from "../components/App/Footer"
import DetailsContent from "../components/CryptoGuysDetail/DetailsContent"
import ProjectStartArea from "../components/Index/ProjectStartArea"

const Details = () => {
    return (
        <Layout>
            <Navbar />
            <Banner/> 
            <DetailsContent/>
            <ProjectStartArea />
            <Footer />
        </Layout>
    );
}

export default Details