import React from 'react'

import Img1 from '../../assets/images/crypto-guys/1.png'
import Img2 from '../../assets/images/crypto-guys/2.png'
import Img3 from '../../assets/images/crypto-guys/3.png'
import Img4 from '../../assets/images/crypto-guys/4.png'
import Img5 from '../../assets/images/crypto-guys/5.png'
import Img6 from '../../assets/images/crypto-guys/6.png'

const CaseStudiesDetailsContent = () => {
    return (
        <section className="case-studies-details-area ptb-50">
            <div className="container">
                <div className="row">
                    <div className="single-case-study-item">
                        <div className="container">
                            <div className="row align-items-center">

                                <div className="col-lg-6 col-md-12">
                                    <div className="case-study-image">
                                        <div className="main-banner-image">
                                            <img src={Img1} alt="banner" className="img-mobile-left" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="case-study-content">
                                        <h2>Fear & Greed Index</h2>
                                        <p>The Fear & Greed Index for Bitcoin and other large cryptocurrencies is a number between 0-100, gathered every day, after analyzing emotions and sentiments from different sources and crunching them into one simple number.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-case-study-item">
                        <div className="container">
                            <div className="row align-items-center">

                                <div className="col-lg-6 col-md-12">
                                    <div className="case-study-content">
                                        <h2>History</h2>
                                        <p>On the history tab, you can select days with slider and that will show you the history in graph. under the graph you can see the min and max value in the selected duration.</p>

                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="case-study-image">
                                        <div className="main-banner-image">
                                            <img src={Img2} alt="banner" className="img-mobile-right" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-case-study-item">
                        <div className="container">
                            <div className="row align-items-center">

                                <div className="col-lg-6 col-md-12">
                                    <div className="case-study-image">
                                        <div className="main-banner-image">
                                            <img src={Img3} alt="banner" className="img-mobile-left" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="case-study-content">
                                        <h2>Compare</h2>
                                        <p>On the compare tab, you will have two sliders and two graphs. you can select year on top slider and different year on bottom slider. Select will update both grapghs and you can compare ...</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-case-study-item">
                        <div className="container">
                            <div className="row align-items-center">

                                <div className="col-lg-6 col-md-12">
                                    <div className="case-study-content">
                                        <h2>Hot Events/Releases</h2>
                                        <p>Hot events and releases tab contains all the upcomming hot events and release details and dates. this is realy imporant informaation to predict the future trends. Usuall coins get pumped near such dates ... NfA </p>

                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="case-study-image">
                                        <div className="main-banner-image">
                                            <img src={Img4} alt="banner" className="img-mobile-right" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-case-study-item">
                        <div className="container">
                            <div className="row align-items-center">

                                <div className="col-lg-6 col-md-12">
                                    <div className="case-study-image">
                                        <div className="main-banner-image">
                                            <img src={Img5} alt="banner" className="img-mobile-left" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="case-study-content">
                                        <h2>About</h2>
                                        <p>On this tab you can get really good informatic stuff especially for newbies. like top exchanges, top coins, tips, glossary etc. ... NfA</p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="single-case-study-item pb-100">
                        <div className="container">
                            <div className="row align-items-center">

                                <div className="col-lg-6 col-md-12">
                                    <div className="case-study-content">
                                        <h2>Information</h2>
                                        <p>Clicking on the buttons or links on the about tab will open the relevent pdfs. Full with required information. You can use the bottom navigation buttons to navigate through pages.</p>

                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="case-study-image">
                                        <div className="main-banner-image">
                                            <img src={Img6} alt="banner" className="img-mobile-right" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default CaseStudiesDetailsContent